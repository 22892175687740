import './styles/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import PromocodeCatalog from './views/PromocodeCatalog'
import CourseCatalog from './views/CourseCatalog'
import SuccessPage from './views/SuccessPage'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/store" element={<PromocodeCatalog />} />
        <Route path="/course" element={<CourseCatalog />} />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </Router>
  );
}

export default App;
