/*
 * Copyright 2024 LLC Campus.
 */

import React, { useEffect, useState } from 'react'
import { fetchConfirmationToken, fetchPromocodeCatalog } from '../services/api'
import '../styles/PromocodeCatalog.css'
import { openYooKassaWidget } from '../yookassa'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ItemGrid from '../components/ItemGrid'
import EmailInputContainer from '../components/EmailInputContainer'

function PromocodeCatalog () {
  const [promocodes, setPromocodes] = useState([])
  const [email, setEmail] = useState('')
  const [selectedPromocodeId, setSelectedPromocodeId] = useState(null)
  const [isWidgetOpen, setIsWidgetOpen] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [hasErrorToast, setHasErrorToast] = useState(false)

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        const data = await fetchPromocodeCatalog()
        if (isMounted) {
          setPromocodes(data.items)
        }
      } catch (error) {
        if (isMounted) {
          if (!hasErrorToast) {
            toast.error(error.message)
            setHasErrorToast(true)
          }
        }
      }
    };

    fetchData()

    return () => {
      isMounted = false
    };
  }, [hasErrorToast])

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
    setIsEmailValid(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(event.target.value))
  }

  const handleCardClick = (promocodeId) => {
    setSelectedPromocodeId(promocodeId)
  }

  const handlePurchaseClick = async () => {
    const selectedPromocode = promocodes.find(promocode => promocode._id === selectedPromocodeId)

    if (selectedPromocode) {
      try {
        const data = await fetchConfirmationToken(selectedPromocodeId, email)

        if (data.confirmationToken && data.transactionId) {
          setIsWidgetOpen(true);
          openYooKassaWidget(data.confirmationToken, selectedPromocode, data.transactionId)
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
  }

  return (
    <div>
      <div id="yookassa-container" className={isWidgetOpen ? '' : 'hidden'}></div>
      <div className={`promocode-catalog ${isWidgetOpen ? 'hidden' : ''}`}>
        <div className="promocode-catalog-header">
          <div className="promocode-catalog-header-title">Подписка Кампус Premium</div>
          <ul className="promocode-catalog-header-list">
            <li>отключение рекламы в приложении</li>
            <li>отзывы и посты до модерации</li>
          </ul>
        </div>
        <ItemGrid
          items ={promocodes}
          selectedItemId={selectedPromocodeId}
          handleCardClick={handleCardClick}
        />
        <EmailInputContainer
          email={email}
          isEmailValid={isEmailValid}
          selectedItemId={selectedPromocodeId}
          handleEmailChange={handleEmailChange}
          handlePurchaseClick={handlePurchaseClick}
        />
      </div>
    </div>
  )
}

export default PromocodeCatalog
