/*
 * Copyright 2024 LLC Campus.
 */

export async function fetchPromocodeCatalog() {
  try {
    const response = await fetch(`/v4/promocode`)

    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson.message)
    }

    const contentType = response.headers.get('content-type')
    if (contentType && contentType.includes('application/json')) {
      return await response.json()
    } else {
      throw new Error('Unexpected response type')
    }
  } catch (error) {
    console.error('Error fetching product catalog:', error)
    throw error
  }
}

export async function fetchConfirmationToken(selectedPromocodeId, email) {
  try {
    const response = await fetch(`/v4/promocode/buy-web`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        templateId: selectedPromocodeId,
        email: email,
      }),
    })

    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson.message)
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching confirmation token:', error)
    throw error
  }
}

export async function checkStatus(transactionId) {
  try {
    const response = await fetch(`/v4/promocode/buy/check-status?transactionId=${transactionId}`)

    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson.message)
    }

    const contentType = response.headers.get('content-type')
    if (contentType && contentType.includes('application/json')) {
      return await response.json()
    } else {
      throw new Error('Unexpected response type')
    }
  } catch (error) {
    console.error('Error fetching product catalog:', error)
    throw error
  }
}

export async function fetchCourseCatalog(courseId) {
  try {
    const response = await fetch(`/v4/promocode/type/course`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        metadata: {
          id: courseId
        }
      })
    })

    if (!response.ok) {
      const responseJson = await response.json()
      throw new Error(responseJson.message)
    }

    const contentType = response.headers.get('content-type')
    if (contentType && contentType.includes('application/json')) {
      return await response.json()
    } else {
      throw new Error('Unexpected response type')
    }
  } catch (error) {
    console.error('Error fetching product catalog:', error)
    throw error
  }
}
